<template lang="pug">
  v-main
    v-container(fluid fill-height)
      v-layout(align-center justify-center)
        v-flex(xs12 sm8 md4)
          v-card.elevation-12
            v-toolbar(dark color="primary")
              v-toolbar-title Login form
            v-card-text
              v-form
                v-text-field(prepend-icon="person" name="login" label="Login" type="email" v-model="auth.email" @keyup.enter="login")
                v-text-field(prepend-icon="lock" name="password" label="Password" id="password" v-model="auth.password" @keyup.enter="login" type="password")
            v-card-actions
              v-spacer
              v-btn(color="primary" @click="login") Login
</template>
<script>
export default {
  data () {
    return {
      auth: {
        user: null,
        email: '',
        password: '',
        message: '',
        userName: '',
        hasErrors: false
      }
    }
  },
  methods: {
    login (event) {
      this.$store.dispatch('userSignIn', { email: this.auth.email, password: this.auth.password })
    }
  },
  watch: {
    error (value) {
      if (value) {
        this.alert = true
      }
    },
    alert (value) {
      if (!value) {
        this.$store.commit('setError', null)
      }
    }
  },
  computed: {
    error () {
      return this.$store.state.error
    },
    loading () {
      return this.$store.state.loading
    },
    displayName () {
      return this.$store.state.user.displayName
    }
  }
}
</script>
